import { default as contactid8Hi55LTBMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/contact.vue?macro=true";
import { default as payments_45conditionsdNfE90DhwwMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/payments-conditions.vue?macro=true";
import { default as privacy_45termsLPdrrIkwsjMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/privacy-terms.vue?macro=true";
import { default as refunds_45conditions8XOlwJ08v9Meta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/refunds-conditions.vue?macro=true";
import { default as terms_45conditionshWOVcOQPQqMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/terms-conditions.vue?macro=true";
import { default as user_45rightskl1zgUZjA4Meta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/user-rights.vue?macro=true";
import { default as giveawayfp1fOngDPTMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/campaign/giveaway.vue?macro=true";
import { default as match6eh1YzaBqOMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/campaign/match.vue?macro=true";
import { default as _91_91code_93_93qvWIHd1Zz4Meta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/index/[[code]].vue?macro=true";
import { default as _91_91code_93_93HTFBR7U3ANMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/store/[[code]].vue?macro=true";
import { default as _91token_93kO6ztddS2xMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/change-password/[token].vue?macro=true";
import { default as forgot_45passwordn49SpzY4BjMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/forgot-password.vue?macro=true";
import { default as loginAxAhdlGOkUMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/login.vue?macro=true";
import { default as profileTRjjEm91oBMeta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/profile.vue?macro=true";
import { default as register83p7SMYVb5Meta } from "C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/register.vue?macro=true";
export default [
  {
    name: "about-contact",
    path: "/about/contact",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/contact.vue").then(m => m.default || m)
  },
  {
    name: "about-payments-conditions",
    path: "/about/payments-conditions",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/payments-conditions.vue").then(m => m.default || m)
  },
  {
    name: "about-privacy-terms",
    path: "/about/privacy-terms",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/privacy-terms.vue").then(m => m.default || m)
  },
  {
    name: "about-refunds-conditions",
    path: "/about/refunds-conditions",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/refunds-conditions.vue").then(m => m.default || m)
  },
  {
    name: "about-terms-conditions",
    path: "/about/terms-conditions",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "about-user-rights",
    path: "/about/user-rights",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/about/user-rights.vue").then(m => m.default || m)
  },
  {
    name: "campaign-giveaway",
    path: "/campaign/giveaway",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/campaign/giveaway.vue").then(m => m.default || m)
  },
  {
    name: "campaign-match",
    path: "/campaign/match",
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/campaign/match.vue").then(m => m.default || m)
  },
  {
    name: "index-code",
    path: "//:code?",
    meta: _91_91code_93_93qvWIHd1Zz4Meta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/index/[[code]].vue").then(m => m.default || m)
  },
  {
    name: "store-code",
    path: "/store/:code?",
    meta: _91_91code_93_93HTFBR7U3ANMeta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/store/[[code]].vue").then(m => m.default || m)
  },
  {
    name: "user-change-password-token",
    path: "/user/change-password/:token()",
    meta: _91token_93kO6ztddS2xMeta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/change-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "user-forgot-password",
    path: "/user/forgot-password",
    meta: forgot_45passwordn49SpzY4BjMeta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "user-login",
    path: "/user/login",
    meta: loginAxAhdlGOkUMeta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileTRjjEm91oBMeta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-register",
    path: "/user/register",
    meta: register83p7SMYVb5Meta || {},
    component: () => import("C:/Projects/CONTIGO/CONTIGO MARKETPLACE/Contigo Web/marketplace-portal-client-nuxt/pages/user/register.vue").then(m => m.default || m)
  }
]