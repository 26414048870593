<script lang="ts" setup>
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/img/logo.png',
    },
    {
      id: 'gilroy-webfonts-kit',
      rel: 'stylesheet',
      href: () => '/GilroyWebfontsKit.css',
    },
  ],
})
</script>

<template>
  <div>
    <div
      class="absolute z-[-10] h-[500px] w-full bg-gradient-to-t from-primary-950 to-primary-700"
    ></div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <PvToast />
    <PvConfirmDialog>
      <template #container="slotProps">
        <div class="grid gap-4 p-6">
          <p>{{ slotProps.message.message }}</p>

          <div class="flex justify-end gap-2">
            <PvButton
              :label="slotProps?.message?.rejectProps?.label ?? 'Cancelar'"
              severity="contrast"
              outlined
              @click="slotProps?.rejectCallback()"
            />
            <PvButton
              :label="slotProps?.message?.acceptProps?.label ?? 'Continuar'"
              @click="slotProps?.acceptCallback()"
            />
          </div>
        </div>
      </template>
    </PvConfirmDialog>
  </div>
</template>

<style></style>
